export const SAVE_INTRODUCTION = 'saveIntroduction'
export const SAVE_SITE_INFO = 'saveSiteInfo'
export const SAVE_COMMENT_RESULT = 'saveCommentResult'
export const SET_COMMENT_QUERY_PAGE = 'setCommentQueryPage'
export const SET_COMMENT_QUERY_BLOG_ID = 'setCommentQueryBlogId'
export const SET_COMMENT_QUERY_PAGE_NUM = 'setCommentQueryPageNum'
export const SET_PARENT_COMMENT_ID = 'setParentCommentId'
export const RESET_COMMENT_FORM = 'resetCommentForm'
export const RESTORE_COMMENT_FORM = 'restoreCommentForm'
export const SET_IS_BLOG_RENDER_COMPLETE = 'setIsBlogRenderComplete'
export const SET_BLOG_PASSWORD_DIALOG_VISIBLE = 'setBlogPasswordDialogVisible'
export const SET_BLOG_PASSWORD_FORM = 'setBlogPasswordForm'
export const SET_FOCUS_MODE = 'setFocusMode'
export const SET_IS_BLOG_TO_HOME = 'setIsBlogToHome'
export const SAVE_CLIENT_SIZE = 'saveClientSize'